<template>
  <div class="container pt-48">
    <div class="row mb-16">
      <div
        class="col-12 col-md-6 d-flex align-items-center justify-content-center flex-gap-16 flex-column flex-lg-row"
      >
        <Guarantee width="38.44px" height="36.94px" />

        <p class="mb-8 fw-bold">
          {{ $label('common.happinessGuarantee.title') }}
        </p>
      </div>

      <div class="col col-md-6 text-center text-lg-start">
        <p class="text-small mb-8">
          {{ $label('common.happinessGuarantee.desc') }}
        </p>

        <NuxtLink to="/algemene-voorwaarden" class="text-small">
          {{ $label('common.happinessGuarantee.moreInfo') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import Guarantee from '~/assets/icons/guarantee.svg'

export default {
  components: {
    Guarantee,
  },
}
</script>

<style lang="scss" scoped>
p {
  line-height: 24px;
}
</style>
